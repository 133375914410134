
import { Component } from 'vue-property-decorator'
import VueStrong from '@/VueStrong'

@Component
export default class Dashboard extends VueStrong {
  loading = false
  snackbarSuccess = false
  snackbarError = false
  snackbarErrorMessage = ''

  cards = [
    {
      icon: 'mdi-rocket-launch',
      title: 'Launch campaign',
      subtitle: "Create a new campaign on facebook without leaving the app, starting from yakkyofy's products",
      action: 'Launch Now',
      link: 'CampaignFlow'
    },
    {
      icon: 'mdi-advertisements',
      title: 'Manage campaings',
      subtitle: 'Start, stop or edit your campaigns directly from our dashboard',
      action: 'View',
      link: 'Insights'
    },
    {
      icon: 'mdi-chart-box',
      title: 'Product Insights',
      subtitle: "Analyze product's campaigns and mix the data with the orders you have on yakkyofy",
      action: 'Analyze',
      link: 'ProductInsights'
    },
    {
      icon: 'mdi-account-cog',
      title: 'Manage your connections',
      subtitle: 'Ad a new facebook account or disconnect an existing one.',
      action: 'Connect a new account',
      link: 'Businesses'
    },
    {
      icon: 'mdi-file-cog',
      title: 'Templates',
      subtitle: 'Create new templates for your adcreatives, customized to your needs and audience.',
      action: 'View Templates',
      link: 'Templates'
    },
    {
      icon: 'mdi-tune',
      title: 'Automate',
      subtitle: 'Let our software monitor your ads and automatically perform actions based on your rules.',
      action: 'View Automations',
      link: 'AutomationsList'
    }
  ]

  get selectedAdAccount() {
    return this.$store.state.account ? this.$store.state.account.adAccount || '' : ''
  }

  created() {
    const error = this.$route.query ? this.$route.query.error : ''
    if (!error) return

    if (error === 'no-page') this.snackbarErrorMessage = 'No page found. Please add a page to your business.'
    this.snackbarError = true
  }

  goTo(routeName: string) {
    this.$router.push({ name: routeName })
  }
}
